import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, breakpoints, spacing}) => ({
  sliderContainer: {
    '& .slide': {
      [breakpoints.down('sm')]: {
        height: 150,
      },
    },
    '& .slider': {
      [breakpoints.down('sm')]: {
        height: 200,
      },
    },
  },
  logo: {
    margin: spacing(0, 10),
    width: 200,
    height: 120,
    display: 'flex',
  },
  awardsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  imageContainer: {
    width: 200,
    '& img': {
      width: '100%',
      height: '100%',
      maxHeight: 120,
      marginTop: 0,
      objectFit: 'contain',
      [breakpoints.down('sm')]: {
        maxHeight: 70,
      },
    },
    [breakpoints.down('sm')]: {
      width: 100,
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 300,
    cursor: 'pointer',
  },
  titleAward: {
    position: 'absolute',
    bottom: 50,
    [breakpoints.down('sm')]: {
      bottom: 0,
    },
    '& h2': {
      textDecoration: 'none',
      color: palette.text.primary,
    },
  },
}))

export default useStyles
