import * as R from 'ramda'
import {arrayOf, shape, string} from 'prop-types'
import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material'
import React from 'react'
import Slider from 'react-animated-slider'

import {Link} from 'gatsby'
import Heading from 'components/UI/Heading'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import 'react-animated-slider/build/horizontal.css'
import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const Awards = ({ctaTitle, title, hat, data, countryData, awardsSlug}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const mapIndexed = R.addIndex(R.map)
  const awardsList = R.splitEvery(3, data)
  const {countryCode, urlPrefix, navLocale} = countryData
  const toAwardsPage = `/${countryCode}/${
    urlPrefix === 'worldwide' ? '' : `${navLocale}/`
  }${awardsSlug}`

  return (
    <Section
      hasVerySmallPadding
      hasPaddingTop={!isMobile}
      hasPaddingBottom={!isMobile}
    >
      <Heading isCentered>
        {title && (
          <Title
            variant="h2"
            type="largeTitle"
            hat={hat}
            isCentered
            color="black"
          >
            {title}
          </Title>
        )}
      </Heading>
      <div className={classes.sliderContainer}>
        {awardsList && (
          <Slider
            autoplay={2500}
            previousButton={isMobile ? null : <ArrowBackIos />}
            nextButton={isMobile ? null : <ArrowForwardIos />}
          >
            {mapIndexed(
              (splitedAwards, index) => (
                <div className={classes.awardsContainer} key={index}>
                  {R.map(
                    award => (
                      <Link
                        className={classes.cardContainer}
                        to="../../awards"
                        state={{clickedAward: award}}
                      >
                        <div className={classes.imageContainer}>
                          <img src={award.logo.file.url} alt={award.name} />
                        </div>
                        <div className={classes.titleAward}>
                          <Title
                            isCentered
                            variant="h2"
                            type={isMobile ? 'verySmallTitle' : 'innerTitle'}
                          >
                            {award.name}
                          </Title>
                        </div>
                      </Link>
                    ),
                    splitedAwards,
                  )}
                </div>
              ),
              awardsList,
            )}
          </Slider>
        )}
      </div>
      {ctaTitle && (
        <RoundButton arrow isCentered color="secondary" href={toAwardsPage}>
          {ctaTitle}
        </RoundButton>
      )}
    </Section>
  )
}

Awards.propTypes = {
  awardsSlug: string,
  countryData: shape({
    countryCode: string,
    urlPrefix: string,
    navLocale: string,
  }),
  ctaTitle: string,
  data: arrayOf(
    shape({
      logo: shape({
        file: shape({contentype: string, url: string}),
        title: string,
      }),
      name: string,
    }),
  ),
  hat: string,
  title: string,
}

Awards.defaultProps = {
  awardsSlug: '',
  countryData: null,
  ctaTitle: '',
  data: arrayOf(
    shape({
      logo: shape({
        file: shape({contentype: '', url: ''}),
        title: '',
      }),
      name: '',
    }),
  ),
  hat: '',
  title: '',
}

export default Awards
